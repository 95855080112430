<template>
    <div class="vue-box">

        <div class="header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>{{project.name}}</el-breadcrumb-item>
                <el-breadcrumb-item>{{m.name}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
 <div class="c-panel-top">
        <el-row :gutter="20">
            <el-col :span="5" v-for="item in muens" :key="item.id">
                <div class="nav-img" @click="goto(item)"> <img :src="item.image"/> <span>{{item.name}}</span>  </div>
            </el-col>
            <el-col :span="4"></el-col>
        </el-row>

 </div>
        <div class="title">任务详情</div>
        <div class="c-panel">
            <el-descriptions :column="1">
                <el-descriptions-item label="任务名称">{{m.name}}</el-descriptions-item>
                <el-descriptions-item label="任务类型">{{m.typeEnum}}</el-descriptions-item>
                <el-descriptions-item label="负责人">{{m.users}}</el-descriptions-item>
                <el-descriptions-item label="状态">{{m.stateDevEnum}}</el-descriptions-item>

                <el-descriptions-item label="计划完成时间">{{m.planDate}}</el-descriptions-item>

                <el-descriptions-item label="实际完成时间">{{m.finishDate}}</el-descriptions-item>

                <el-descriptions-item label="任务描述" :labelStyle="{width: '180px'}">
                    <div v-html="m.content"></div>
                </el-descriptions-item>

            </el-descriptions>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            one: {},
            srcList: [],
            project: {},
            m: {},
            muens: [
                 { name: '产品说明', url: 'productDetail-detail', image: require('@/static/img/cpsm.png'), id: 1 },
                 { name: '开发排期', url: 'taskSchedule-list', image: require('@/static/img/jihua.png'), id: 2 },
                 { name: '项目风险等级', url: 'taskRisk-list', image: require('@/static/img/fengxian.png'), id: 3 },
                 { name: '测试对照表', url: 'taskTest-list', image: require('@/static/img/yf.png'), id: 4 },
            ]
        };
    },
    methods: {
        f5() {
            this.sa.get("/task/find/" + this.m.id).then(res => {
                this.m = res.data;
                this.m.project = this.project;
            });
        },
        goto(item){
            this.sa_admin.navigateTo(item.url, this.m);
        }
    },
    mounted() {},
    created() {
        this.m = this.sa_admin.params;
        console.log(this.m);
        this.project = this.m.project;
        this.f5();
    }
};
</script>

<style>
.header {
    margin: 20px;
}

.nav-img {
    width: 100%;
    background-color: #ffffff;
    height: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-img img {
    width: 50px;
    height: 50px;
    margin-right: 40px;
}

.title {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2d2d2d;
    line-height: 29px;
    padding-left: 20px;
    border-left: 3px solid #4983f4;
    margin-left: 20px;
}

.c-panel-top {
    margin-left: 20px;
    margin-bottom: 30px;
}
</style>